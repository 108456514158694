/* eslint-disable @typescript-eslint/no-explicit-any */

import { TranslocoService } from '@jsverse/transloco';
import { GetContextMenuItemsParams, GridApi, MenuItemDef } from 'ag-grid-community';

export enum ExportFileType {
	CSV = 'csv',
	EXCEL = 'excel'
}

/**
 * This method deals with sorting the values when blanks are available in the list
 * @param aValue - string representation of column data
 * @param bValue - string representation of column data
 * @param isDescending - boolean representation of sort direction.
 * @returns
 *  - `0`  aValue is the same as bValue
 *  - `> 0` Sort aValue after bValue
 *  - `< 0` Sort aValue before bValue
 */
export function sortWithBlankValues(aValue: any, bValue: any, isDescending: boolean): number {
	if (!isDescending) {
		return sortOrder(aValue, bValue, isDescending);
	}
	return sortOrder(bValue, aValue, isDescending);
}

function sortOrder(a: any, b: any, isDescending: boolean): number {
	if (a === b) {
		return 0;
	}
	// Put blank values at the top for descending order, and at the bottom for ascending order.
	else if (a === '' || a === undefined) {
		return isDescending ? -1 : 1;
	} else if (b === '' || b === undefined) {
		return isDescending ? 1 : -1;
	}

	// Compare non-blank values normally with localeCompare
	return isDescending ? b.localeCompare(a) : a.localeCompare(b);
}

/**
 * Compares two specification strings to order them numerically based on a prefix and a custom suffix.
 * Strings should start with a number and end with the specified suffix for accurate comparisons.
 * If the string does not match the expected format, it is sorted as if it has the highest possible order.
 *
 * @param {string} valueA - The first specification string to compare.
 * @param {string} valueB - The second specification string to compare.
 * @param {string} suffix - The suffix that each string should end with to be considered valid.
 * @returns {number} - Returns -1 if valueA should come before valueB, 1 if valueA should come after valueB,
 * or 0 if they are equivalent as per locale comparison.
 */
export function sortWithAlphaNumericValues(valueA: any, valueB: any, suffix: string): number {
	// Special value for sorting items with 'unassigned' or non-numeric text
	const specialSortValue = Number.MAX_SAFE_INTEGER;

	// Helper function to extract the numeric part from the specification
	const extractNumber = (value: any): number => {
		const pattern = new RegExp(`^(\\d+)-${suffix}$`);
		const matches = value && value.match(pattern);
		return matches ? parseInt(matches[1], 10) : specialSortValue;
	};

	const aValue = extractNumber(valueA);
	const bValue = extractNumber(valueB);

	// Compare the extracted numbers, placing special characters below numbers
	if (aValue < bValue) {
		return -1;
	} else if (aValue > bValue) {
		return 1;
	}

	// Use locale-based comparison if numeric values are the same
	return valueA.localeCompare(valueB);
}

/**
 *	sorts the grid based on the user's locale
 * @param locale - string representation of user's selected locale
 */
export function intlCollatorSort(locale?: string): any {
	const collator = new Intl.Collator(locale, { sensitivity: 'variant' });
	return (valueA: any, valueB: any) => collator.compare(valueA, valueB);
}

/**
 *	filters the grid based on the user's locale
 * @param locale - string representation of user's selected locale
 */
export function intlCollatorFilter(locale?: string) {
	const collator = new Intl.Collator(locale, { sensitivity: 'variant', usage: 'search' });
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	return (filterText: any, cellValue: any) => collator.compare(filterText, cellValue) === 0;
}

/**
 * used to define custom context menu items for the ag-Grid
 * @param params- params is of type GetContextMenuItemsParams
 */
export function getCustomContextMenuItems(
	params: GetContextMenuItemsParams,
	translocoService: TranslocoService
): (string | MenuItemDef)[] {
	const onlySelectedRows = params.api.getSelectedRows().length > 0;
	return [
		'copy',
		'copyWithHeaders',
		'copyWithGroupHeaders',
		'separator',
		{
			name: onlySelectedRows
				? translocoService.translate('ag-grid.exportSelected')
				: translocoService.translate('ag-grid.export'),
			subMenu: [
				{
					name: translocoService.translate('ag-grid.csvExport'),
					action: () => exportData(params.api, ExportFileType.CSV, onlySelectedRows),
					icon: '<span class="ag-icon ag-icon-csv ag-icon ag-icon-export"></span>'
				},
				{
					name: translocoService.translate('ag-grid.excelExport'),
					action: () => exportData(params.api, ExportFileType.EXCEL, onlySelectedRows),
					icon: '<span class="ag-icon ag-icon-excel"></span>'
				}
			]
		}
	];
}

/**
 * export ag-grid data based on selection export type
 * @param gridApi - represents ag-grid table gridApi
 * @param format - represents format of the export file type
 */
export function exportData(gridApi: GridApi, format: ExportFileType, onlySelected = false): void {
	const visibleColumns = gridApi?.getAllDisplayedColumns();
	// Removing action column from list of visible columns so as to not display empty column in excel and csv
	const columnKeys = visibleColumns
		?.filter((column) => column.getColDef()?.field !== 'action' && column.getColDef()?.checkboxSelection !== true)
		.map((column) => column.getColId());
	const params = {
		onlySelected: onlySelected,
		columnKeys: columnKeys
	};

	if (format === ExportFileType.CSV) {
		gridApi.exportDataAsCsv(params);
	} else if (format === ExportFileType.EXCEL) {
		gridApi.exportDataAsExcel(params);
	}
}

/**
 * Resets all AG Grid configurations to their default state.
 * This function clears filters, deselects all rows, resets row heights, clears row groupings,
 * resets column visibility, grouping, and state (including column width), and displays a loading overlay.
 *
 * @param {GridApi} gridApi - The AG Grid API instance that manages the grid's state and operations.
 */
export function resetAgGrid(gridApi: GridApi): void {
	if (gridApi) {
		// Clear all filters
		gridApi.setFilterModel(null);

		// Deselect all rows
		gridApi.deselectAll();

		// Reset row heights
		gridApi.resetRowHeights();

		// Clear any row groupings
		gridApi.setRowGroupColumns([]);

		// Clear column state (removes any custom visibility or grouping)
		gridApi.applyColumnState({
			state: [],
			applyOrder: true
		});

		// Reset columns to their default state (including column width)
		gridApi.resetColumnState();

		// Show loading overlay after resetting the grid
		setTimeout(() => {
			gridApi.showLoadingOverlay();
		}, 0);
	}
}
