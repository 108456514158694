import { proxyCustomElement, HTMLElement, h, Host } from '@stencil/core/internal/client';

const prismGraduatedContainerCss = ":host{display:flex;--_padding:var(--prism-graduated-container-padding, 0px)}:host([orientation=horizontal]){flex-direction:column}.pwc-scale-wrapper.has-slider.vertical{padding-block:var(--_padding)}.pwc-scale-wrapper.has-slider.horizontal{padding-inline:var(--_padding)}";
const PrismGraduatedContainerStyle0 = prismGraduatedContainerCss;

const PrismGraduatedContainer$1 = /*@__PURE__*/ proxyCustomElement(class PrismGraduatedContainer extends HTMLElement {
    constructor() {
        super();
        this.__registerHost();
        this.__attachShadow();
        this.ticks = [];
        this.orientation = 'vertical';
        this.fader = false;
    }
    componentDidRender() {
        if (this.sliderElement) {
            this.wrapperBefore.classList.add('has-slider');
            this.wrapperAfter.classList.add('has-slider');
            if (this.ticks) {
                this.sliderElement.ticks = this.ticks;
            }
            const computedStyle = getComputedStyle(this.hostElement, null);
            const customPadding = computedStyle.getPropertyValue('--prism-graduated-container-padding');
            const padding = customPadding ? customPadding : '0px';
            if (this.orientation === 'vertical') {
                this.sliderElement.style.setProperty('--prism-slider-padding-inline', 'var(--prism-spacing-global-medium)');
                this.sliderElement.style.setProperty('--prism-slider-padding-block', padding);
            }
            else {
                this.sliderElement.style.setProperty('--prism-slider-padding-inline', padding);
                this.sliderElement.style.setProperty('--prism-slider-padding-block', 'var(--prism-spacing-global-medium)');
            }
        }
    }
    render() {
        return (h(Host, { key: '105442fa88f31c645b09b1a7cca77bbbe84db2e3' }, h("div", { key: '5afa55181dbf1d940e03bcaba41ab415f758384e', class: `pwc-scale-wrapper ${this.orientation}${this.fader ? ' fader' : ''}`, ref: (el) => (this.wrapperBefore = el) }, h("slot", { key: 'c42d1bc35b6cd520913c3f9cbc21dfbd6cf5cf56', name: "scale-before", onSlotchange: (e) => this.scaleSlotChange(e, true) })), h("slot", { key: '234c3566683ca12d262688153ad15fb28aab3539', onSlotchange: (e) => this.mainSlotChange(e) }), h("div", { key: '79d0be7d98327922195e37a74c977d15745b84e6', class: `pwc-scale-wrapper after ${this.orientation}${this.fader ? ' fader' : ''}`, ref: (el) => (this.wrapperAfter = el) }, h("slot", { key: '835c06becd846efd48a04ee7d43e17079d191e77', name: "scale-after", onSlotchange: (e) => this.scaleSlotChange(e, false) }))));
    }
    scaleSlotChange(event, before) {
        if (event.target.assignedElements()[0].tagName === 'PRISM-SCALE') {
            const scale = event.target.assignedElements()[0];
            scale.orientation = this.orientation;
            this.ticks = scale.ticks;
            if (before) {
                scale.position = this.orientation === 'vertical' ? 'left' : 'top';
            }
            else {
                scale.position = this.orientation === 'vertical' ? 'right' : 'bottom';
            }
        }
    }
    mainSlotChange(event) {
        const slottedElement = event.target.assignedElements()[0];
        if (slottedElement && slottedElement.tagName === 'PRISM-SLIDER') {
            this.sliderElement = slottedElement;
            this.fader = this.sliderElement.fader;
        }
    }
    get hostElement() { return this; }
    static get style() { return PrismGraduatedContainerStyle0; }
}, [1, "prism-graduated-container", {
        "orientation": [1],
        "fader": [4]
    }]);
function defineCustomElement$1() {
    if (typeof customElements === "undefined") {
        return;
    }
    const components = ["prism-graduated-container"];
    components.forEach(tagName => { switch (tagName) {
        case "prism-graduated-container":
            if (!customElements.get(tagName)) {
                customElements.define(tagName, PrismGraduatedContainer$1);
            }
            break;
    } });
}

const PrismGraduatedContainer = PrismGraduatedContainer$1;
const defineCustomElement = defineCustomElement$1;

export { PrismGraduatedContainer, defineCustomElement };

