<div
	[style.background-image]="backgroundImage"
	class="sh-device-image"
	[class.sh-virtual-device]="isVirtual"
	[class.sh-offline-device]="isOffline"
	[matTooltipDisabled]="disableTooltip"
	[matTooltip]="deviceTooltip"
	matTooltipPosition="above"
>
	@if (!deviceModel) {
		<span>--</span>
	}
</div>

@if (!isVirtual) {
	@if (isPasswordEnabled && !isOffline) {
		<img
			class="sh-lock-icon"
			[src]="isAuthenticated ? './assets/icons/lock-only-unlocked.svg' : './assets/icons/lock-only-locked.svg'"
		/>
	}

	@if (incompatibleFirmware) {
		<prism-status-indicator class="sh-firmware-status" shape="circle" status="bad"></prism-status-indicator>
	}

	@if (linked !== undefined) {
		<img
			class="sh-link-icon"
			[src]="
				!linked
					? './assets/images/dockable-transmitter-status/unlinked.svg'
					: './assets/images/dockable-transmitter-status/linked.svg'
			"
		/>
	}
}
